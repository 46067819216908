import React from 'react';
import logo from './logo.svg';
import StandingDesk from './assets/standing-desk.png'
import './App.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Link,
  useLocation
} from "react-router-dom";
import Base64 from './pages/Base64'
import FamilyDraw from './pages/FamilyDraw'

export default function App() {
  return (
    <Router>
      <div>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/family" component={FamilyDraw} />
          <Route path="/base64" component={Base64}>
          </Route>
          <Route exact path="/" render={() => (
            <div className="App">
              <header className="App-header">
                {/* <img src={logo} className="App-logo" alt="logo" /> */}
                <img src={StandingDesk} className="App-logo" alt="logo" />
                <h1 className="App-title">Imani <br/> <span>Development Studio</span></h1>
              </header>
              <p className="App-intro">
                Websites, Game Development, Apps, Custom Builds, Consulting
              </p>
              <a href="mailto:business@imanidev.studio" className="btn">Contact Me</a>
            </div>
          )} />
          <Route path="*">
            <NoMatch />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

function NoMatch() {
  let location = useLocation();

  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
}