import React, { useRef } from 'react'
// import PropTypes from 'prop-types'

const styles = {
    container: {
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        flexGrow:1,
        minHeight: '100vh',
    },
    list: {
        flexDirection:'column'
    },
    input: {
        fontSize: '0.8rem',
        width: '100%',
        height: '6rem',
    },
    output: {
        fontSize: '0.8rem',
        width: '100%',
        height: '6rem',
    }
}

function Base64(props) {
    const input = useRef()
    const output = useRef()
    return (
        <div style={styles.container}>
            <div style={styles.list}>
                <label htmlFor='input'>Base64:</label>
                <textarea style={styles.input} name='input' ref={input} onChange={(e) => {
                    console.log("Value:\n" + window.atob(input.current.value))
                    output.current.value = window.atob(input.current.value)
                }} />
                <label htmlFor='output'>UTF8:</label>
                <textarea style={styles.output} name='output' ref={output} onChange={(e) => {
                    console.log("Value:\n" + window.btoa(output.current.value))
                    input.current.value = window.btoa(output.current.value)
                }} />
            </div>
        </div>
    )
}

Base64.propTypes = {

}

export default Base64

