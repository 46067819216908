import React, { useRef, useEffect, useState, useReducer } from 'react'
import qs from 'query-string'
import randomSeed from 'random-seed'
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
// import PropTypes from 'prop-types'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    minHeight: '100vh',
  },
  list: {
    flexDirection: 'column',
    marginTop: 10,
  },
  input: {
    fontSize: '0.8em',
    width: '100%',
    height: '2em',
    padding: '4px',
    borderRadius: '4px',
  },
  button: {
    fontSize: '0.8em',
    paddingTop: 0,
    paddingBottom: 0,
    height: '100%',
  },
  output: {
    fontSize: '0.8rem',
    width: '100%',
    height: '6rem',
  },
  inputList: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: 500,
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: 20,
  },
  elem: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 5,
  },
  column: {
    flexDirection: 'column',
    marginRight: '4px',
  }
}

const initialValues = {
  friends: [
    {
      name: '',
    },
  ],
}

const capitalizeFirst = (string) => (string && string.length ? string.charAt(0).toUpperCase() + string.slice(1) : "")

const randomizeArray = (random, array) => {
  let array2 = []
  while(array.length != 0) {
    let randomIndex = random.intBetween(0,array.length -1)
    array2.push(array[randomIndex])
    array.splice(randomIndex,1)
  }
  return array2
}

function FamilyDraw(props) {
  const [names, setNames] = useState("")
  const [randomNames, setRandNames] = useState("")
  const [seed, setSeed] = useState()

  useEffect(() => {
    const { query } = qs.parseUrl(window.location.href) 
    const { names:namesBase64, seed } = query
    console.log("query", namesBase64, seed)
    let nameList
    if (namesBase64) {
      nameList = window.atob(namesBase64)
      console.log('nameList', nameList)
      setNames(nameList)
    }
    if (seed) {
      setSeed(seed)

      const r = randomSeed(seed)
      console.log('random', r.random())
      if (nameList)
        randomizeArray(r, nameList.split(","))
    }
  }, [])

  useEffect(() => {
    console.log('generate random list', names)
    if (names.length <= 0)
      return
    
    const r = randomSeed(seed)
    console.log('random', r.random())
    let namesNotEqual = true
    let iteration = 0
    let randomNames
    while(namesNotEqual && iteration < 40) {
      randomNames = randomizeArray(r, names.split(","))
      let namesEqual = false
      const nameList = names.split(",")
      console.log("randomNames", randomNames)
      for (let index = 0; index < nameList.length; index++) {
        if (nameList[index] == randomNames[index] && !namesEqual)
          namesEqual = true
      }
      console.log('namesEqual', namesEqual, iteration)
      iteration++
      if (!namesEqual)
        namesNotEqual = false
    }

    setRandNames(randomNames)
  }, [names])

  return (
    <div style={styles.container}>
      <div style={styles.list}>
        <Formik
          initialValues={{ friends: names.split(',').map(name=>({name})) }}
          enableReinitialize={true}
          onSubmit={async (values, actions) => {
            const names = values.friends.map(friend => friend.name)
            setNames(names.join(','))
            
            // if (!seed)
              window.location.href = qs.stringifyUrl({
                url: window.location.origin + window.location.pathname,
                query: {
                  names: window.btoa(names.join(",")),
                  seed: Math.floor(Math.random() * 10000)
                }
              })
            // alert(JSON.stringify(values, null, 2));
          }}
        >
          {({ values }) => (
            <Form>
              <FieldArray name="friends">
                {({ insert, remove, push }) => (
                  <div className="list" style={styles.inputList}>
                    {values.friends.length > 0 &&
                      values.friends.map((friend, index) => (
                        <div style={styles.elem} key={index}>
                          <div style={styles.column} >
                            {/* <label htmlFor={`friends.${index}.name`}>Name </label> */}
                            <Field name={`friends.${index}.name`} placeholder="Name" type="text" style={styles.input} value={capitalizeFirst(friend.name)} />
                            <ErrorMessage name={`friends.${index}.name`} component="div" className="field-error" />
                          </div>
                          <div style={styles.column} >
                            <button type="button" className="btn-secondary" style={styles.button} onClick={() => remove(index)}>X</button>
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </FieldArray>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: 20 }}>
                <button type="submit" className="btn" >Generate New Seed</button>
                <button type="button" className="btn" onClick={() => push({ name: '' })}>Add Member</button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {names.length > 0 && <div style={styles.list}>
        <p>Names List:</p>
        <ul>
          {names.split(',').map((name, i) => (
            <li key={i}>{capitalizeFirst(name)} ----> {capitalizeFirst(randomNames[i])}</li>
          ))}
        </ul>
      </div>}
    </div>
  )
}

FamilyDraw.propTypes = {

}

export default FamilyDraw

